import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MapperService } from './mapper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends MapperService {
  constructor(http: HttpClient) {
    super('notifications/help', http);
  }

  public sendFeedback(body: any) {
    return this.create('?type=feedback')(body);
  }

  public sendHelp(body: any) {
    return this.create('?type=help')(body);
  }
}
